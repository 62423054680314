import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { SpinnerPage } from '@zastrpay/layout';

import { CurrentKycRequestProvider } from './CurrentKycRequestProvider';
import { useKycRequest } from './KycRequestProvider';
import { KycRequest } from './models';

export const CurrentKycRequest: React.FC = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [currentKycRequest, setCurrentKycRequest] = useState<KycRequest>();
    const { getById, loaded } = useKycRequest();

    useEffect(() => {
        if (!loaded) {
            return;
        }

        const kycRequest = id ? getById(id) : undefined;
        if (!kycRequest) {
            navigate('/');
        }

        setCurrentKycRequest(kycRequest);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);

    if (!currentKycRequest) {
        return <SpinnerPage />;
    }

    return (
        <CurrentKycRequestProvider currentKycRequest={currentKycRequest}>
            <Outlet />
        </CurrentKycRequestProvider>
    );
};
