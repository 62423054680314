import { Subtitle2 } from '@fluentui/react-components';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, TranslatableError } from '@zastrpay/common';
import { Alert, Body, ErrorTrans, Link, Pin, PinElement, Timer, TimerElement, Title } from '@zastrpay/components';
import { BackButton, Page, useHeaderSlot } from '@zastrpay/layout';
import { OTP_RESEND_WAIT } from '@zastrpay/pages';

export type PhoneSetupProps = {
    phone: string;
    onComplete?: () => void;
};

// TODO: replace with shared OtpInput
export const PhoneSetup: React.FC<PhoneSetupProps> = (props) => {
    const timer = useRef<TimerElement>(null);
    const pin = useRef<PinElement>(null);

    const navigate = useNavigate();

    const { t, i18n } = useTranslation('registration');
    const { generateOtpCode, verifyOtpCode } = useAuth();

    const [error, setError] = useState<TranslatableError>();
    const [canSend, setCanSend] = useState(false);

    useHeaderSlot('left', <BackButton onClick={() => navigate(-1)} />);

    const validateOtp = async (otp?: string) => {
        if (!otp) {
            setError(undefined);
            return;
        }

        try {
            await verifyOtpCode('Phone', otp);
            props.onComplete?.();
        } catch (error) {
            setError(ensureResponseError(error));
            pin.current?.reset();
        }
    };

    const resendOtp = async () => {
        try {
            pin.current?.reset();

            await generateOtpCode('Phone', props.phone, i18n.language);
        } catch (error) {
            setError(ensureResponseError(error));
        }

        setCanSend(false);
        timer.current?.reset();
    };

    const timerEnded = () => {
        setCanSend(true);
    };

    return (
        <Page>
            <Title>{t('otp.title')}</Title>
            <Body>{t('otp.subTitle')}</Body>
            <Subtitle2>{props.phone}</Subtitle2>
            <Pin ref={pin} length={6} onInput={validateOtp} autoFill autoFocus hyphenated={true} />
            {error && (
                <Alert type="error">
                    <ErrorTrans t={t} error={error} additionalContext="Phone" />
                </Alert>
            )}
            <Body>
                {t('otp.didNotGetCode')}{' '}
                {canSend ? (
                    <Link onClick={resendOtp} inline>
                        {t('otp.sendAgain')}
                    </Link>
                ) : (
                    <Trans
                        t={t}
                        i18nKey="otp.sendAgainIn"
                        components={{
                            timer: <Timer seconds={OTP_RESEND_WAIT} ref={timer} onEnd={timerEnded} />,
                        }}
                    />
                )}
            </Body>
        </Page>
    );
};
