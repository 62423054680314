import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormattedDate } from '@zastrpay/components';

import { KycRequestAlert } from '../KycRequestAlert';
import { KycRequest } from '../models';

export type SelfDeclaredPepQuestionnaireRequestProps = {
    request: KycRequest;
};

export const SelfDeclaredPepQuestionnaireRequest: React.FC<SelfDeclaredPepQuestionnaireRequestProps> = ({ request }) => {
    const navigate = useNavigate();

    const { t } = useTranslation('kyc-requests');

    if (!request.assignment) {
        throw new Error('Kyc Request of type SelfDeclaredPepQuestionnaire is missing assignment');
    }

    return (
        <KycRequestAlert
            title={t('selfDeclaredPepQuestionnaire.title')}
            action={t('selfDeclaredPepQuestionnaire.action')}
            onAction={() => navigate(`/kyc-request/${request.id}/questionnaire`)}
        >
            <Trans
                t={t}
                i18nKey="selfDeclaredPepQuestionnaire.description"
                context={request.state === 'PendingOverdue' ? 'Overdue' : undefined}
                components={{ dueOn: <FormattedDate value={request.dueOn} format="date" /> }}
            />
        </KycRequestAlert>
    );
};
