import { Time } from '@zastrpay/common';
import { Address } from '@zastrpay/customers';

export type KycRequestType =
    | 'SofQuestionnaire'
    | 'SofDocument'
    | 'SowQuestionnaire'
    | 'SowDocument'
    | 'PoaDocument'
    | 'PoiDocument'
    | 'EmailVerification'
    | 'InitialVerification'
    | 'SelfDeclaredLinksQuestionnaire'
    | 'SelfDeclaredPepQuestionnaire';

export type KycRequestState =
    | 'Pending'
    | 'PendingOverdue'
    | 'DataRejected'
    | 'DataReceived'
    | 'PendingExternalVerification'
    | 'PendingManualReview'
    | 'Completed';

export type DataMismatchReason =
    | 'AddressMismatch'
    | 'NameMismatch'
    | 'NationalityMismatch'
    | 'PlaceOfBirthMismatch'
    | 'DateOfBirthMismatch';

export type InvalidDocumentReason =
    | 'InvalidDocumentType'
    | 'Expired'
    | 'NoDate'
    | 'TooOld'
    | 'InsufficientInformation'
    | 'InvalidDocument'
    | 'WrongFormatCannotOpenDocument'
    | 'MissingInformation'
    | 'InadmissibleOther'
    | 'Damaged'
    | 'AlreadyOnFile';

export type PoorDocumentQualityReason = 'ImageTooDark' | 'Blurry' | 'Illegible' | 'Cropped';

export type KycRequestStateDetails = {
    dataMismatchReason?: DataMismatchReason;
    invalidDocumentReason?: InvalidDocumentReason;
    poorDocumentQualityReason?: PoorDocumentQualityReason;
};

export type Assignment = {
    id: string;
    questionnaireId: string;
};

export type KycRequestCreationDetails = {
    highRiskCountries?: string[];
};

export type ReferenceEntityType = 'ReKycVerification' | 'PeriodicReview' | 'Limit' | 'AdHoc';

export type ReferenceEntity = {
    id: string;
    type: ReferenceEntityType;
};

export type KycRequest = {
    id: string;
    customerId: string;

    dueOn?: Date;
    dueAfter?: Time;
    assignment?: Assignment;
    type: KycRequestType;
    state: KycRequestState;
    stateDetails?: KycRequestStateDetails;
    creationDetails?: KycRequestCreationDetails;

    createdOn: Date;
    lastModifiedOn: Date;
    lastCorrelationId: string;
    referenceEntities: ReferenceEntity[];
};

export type DocumentType =
    | 'BankAccountStatement'
    | 'CreditCardStatement'
    | 'Payslip'
    | 'OtherSourceOfFundsOrSourceOfWealth'
    | 'AddressRegistration'
    | 'UtilityBill'
    | 'IdCard'
    | 'Passport'
    | 'ResidencePermit'
    | 'RefugeeDocument'
    | 'OtherProofOfAddress';

export type KycDocument = {
    id: string;
    type: DocumentType;
    number?: string;
    issuedBy?: string;
    expiresOn?: string;
};

export type SubmitDataRequest = {
    address?: Address;
    identityData?: IdentityData;
    documents?: KycDocument[];
};

export type IdentityData = {
    firstName: string;
    lastName: string;
    maidenName?: string;
    middleName?: string;
    dateOfBirth: string;
    placeOfBirth: string;
    countryOfBirth?: string;
    nationality: string;
};

export const isRequiredKycRequest = (request: KycRequest): boolean =>
    request.type === 'InitialVerification' || request.state === 'DataRejected' || request.state === 'PendingOverdue';

export const isVisibleKycRequest = (request: KycRequest): boolean =>
    request.state === 'Pending' || request.state === 'PendingOverdue' || request.state === 'DataRejected';

export const sort = (first: KycRequest, second: KycRequest) => {
    if (first.dueOn && second.dueOn) {
        return first.dueOn.getTime() - second.dueOn.getTime();
    } else if (!first.dueOn) {
        return 1;
    } else if (!second.dueOn) {
        return -1;
    } else {
        return first.createdOn.getTime() - second.createdOn.getTime();
    }
};
