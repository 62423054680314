import { ReactNode } from 'react';

import { ActiveKycRequestType } from './ActiveKycRequestProvider';
import { KycRequestPage } from './KycRequestPage';
import { KycRequest } from './models';
import { EmailVerificationRequest } from './types/EmailVerificationRequest';
import { InitialVerificationRequest } from './types/InitialVerificationRequest';
import { PoaDocumentRequest } from './types/PoaDocumentRequest';
import { PoiDocumentRequest } from './types/PoiDocumentRequest';
import { SelfDeclaredLinksQuestionnaireRequest } from './types/SelfDeclaredLinksQuestionnaireRequest';
import { SelfDeclaredPepQuestionnaireRequest } from './types/SelfDeclaredPepQuestionnaireRequest';
import { SofwDocumentRequest } from './types/SofwDocumentRequest';
import { SofwQuestionnaireRequest } from './types/SofwQuestionnaireRequest';

const getType = (request: KycRequest): ReactNode => {
    switch (request.type) {
        case 'EmailVerification':
            return <EmailVerificationRequest request={request} />;
        case 'InitialVerification':
            return <InitialVerificationRequest request={request} />;
        case 'PoaDocument':
            return <PoaDocumentRequest request={request} />;
        case 'PoiDocument':
            return <PoiDocumentRequest request={request} />;
        case 'SofDocument':
        case 'SowDocument':
            return <SofwDocumentRequest request={request} />;
        case 'SofQuestionnaire':
        case 'SowQuestionnaire':
            return <SofwQuestionnaireRequest request={request} />;
        case 'SelfDeclaredLinksQuestionnaire':
            return <SelfDeclaredLinksQuestionnaireRequest request={request} />;
        case 'SelfDeclaredPepQuestionnaire':
            return <SelfDeclaredPepQuestionnaireRequest request={request} />;
    }
};

export type ActiveKycRequestProps = {
    kycRequest: KycRequest;
    mode: ActiveKycRequestType;
    skippable?: boolean;
};

export const ActiveKycRequest = ({ kycRequest, mode, skippable = false }: ActiveKycRequestProps): ReactNode => {
    if (!kycRequest) {
        return null;
    }

    return (
        <KycRequestPage kycRequest={kycRequest} mode={mode} skippable={skippable}>
            {getType(kycRequest)}
        </KycRequestPage>
    );
};
