import { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { analyticsConfig } from './config';
import { CookieBanner } from './CookieBanner';
import { CookieConsent } from './model';

const consentCookieName = 'cookie_consent';

const getExpireDate = (date: Date) => {
    const expireDate = new Date(date);
    expireDate.setFullYear(expireDate.getFullYear() + 1);
    return expireDate;
};

export type CookieConsentContext = {
    cookieConsent?: CookieConsent;
    analyticsEnabled: boolean;
    showBanner: boolean;
    setShowBanner: (show: boolean) => void;
    setSubmitted: () => void;
    openCookieBanner: () => void;
    setConsents: (consents: CookieConsent) => void;
};

const Context = createContext<CookieConsentContext | null>(null);

export const CookieConsentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [cookies, setCookie] = useCookies([consentCookieName]);
    const [cookieConsent, setCookieConsents] = useState<CookieConsent>();
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const analyticsEnabled = analyticsConfig.enabled;

    useEffect(() => {
        if (!cookies.cookie_consent) {
            setShowBanner(true);
            setCookieConsents({ date: undefined, types: undefined, submitted: false });
        } else {
            setCookieConsents(cookies.cookie_consent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setConsents = (consents: CookieConsent) => {
        setCookie(consentCookieName, consents, {
            path: '/',
            expires: getExpireDate(consents.date ?? new Date()),
        });
        setCookieConsents(consents);
    };

    const setSubmitted = () => {
        if (!cookieConsent || !cookies[consentCookieName]) return;

        cookieConsent.submitted = true;
        setConsents(cookieConsent);
    };

    const openCookieBanner = () => {
        if (!cookieConsent || !cookies[consentCookieName]) return;

        setShowBanner(true);
        setConsents(cookieConsent);
    };

    return (
        <Context.Provider
            value={{ cookieConsent, analyticsEnabled, showBanner, setShowBanner, setSubmitted, openCookieBanner, setConsents }}
        >
            {children}
            <CookieBanner />
        </Context.Provider>
    );
};

export const useCookieConsent = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useContext must be used within an CookieConsentProvider');
    }

    return context;
};
