import { makeStyles } from '@fluentui/react-components';
import { useEffect, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { ensureTranslatableError, isResponseError, pick, sessionStorage, TranslatableError } from '@zastrpay/common';
import { Body, Button, Dialog, ErrorTrans } from '@zastrpay/components';
import { BackButton, useLayout } from '@zastrpay/layout';
import { Address, PhoneInput } from '@zastrpay/pages';

import { AccountSetup, Step as SubStep } from './AccountSetup';
import { CodePage } from './CodePage';
import { InformationPage } from './InformationPage';
import { Contact, Identity, Merchant } from './models';
import { PhoneSetup } from './PhoneSetup';
import { PinSetup } from './PinSetup';

type PhoneInputStep = { type: 'phone'; error?: TranslatableError };
type PhoneVerifyStep = { type: 'phone-verify'; phone: string; initialVerificationPending?: boolean };

type PinInputStep = { type: 'pin' };

type InfoStep = { type: 'info' };
type AccountStep = { type: 'account'; step?: SubStep };
type CodeStep = { type: 'code' };

type Step = PhoneInputStep | PhoneVerifyStep | PinInputStep | InfoStep | AccountStep | CodeStep;

export type StepType = Step['type'];

type ExistingCustomerData = Partial<Identity> & {
    contact?: Partial<Contact>;
    address?: Partial<Address>;
};

const REGISTRATION_CONTEXT_KEY = 'registrationContext';

export type RegistrationProps = {
    handleIncompleteVerification?: boolean;
    compact?: boolean;
    customerData?: ExistingCustomerData;
    merchant?: Merchant;
    onRegister?: () => void;
    onLogin?: () => void;
    onBack?: () => void;
    onStepChange?: (step: StepType) => void;
};

export const Registration: React.FC<RegistrationProps> = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation('registration');
    const { startCustomerSession, createOtpAuthFactor, generateOtpCode, logout, session, state } = useAuth();
    const { setHeaderSlot, clearHeaderSlot } = useLayout();

    const [step, setStep] = useState<Step>();

    const [showOverlay, setShowOverlay] = useState(false);
    const [enteredPhone, setEnteredPhone] = useState('');
    const [currentEnteredPhone, setCurrentEnteredPhone] = useState<string | null>('');

    useEffect(() => {
        trackPage('registration');
    }, []);

    const defaultAccountValues = useMemo(
        () => ({
            personal: props.customerData && pick(props.customerData, 'firstName', 'lastName', 'maidenName', 'middleName'),
            general: props.customerData && pick(props.customerData, 'countryOfBirth', 'dateOfBirth', 'nationality', 'placeOfBirth'),
            email: props.customerData?.contact && pick(props.customerData?.contact, 'email'),
            address: props.customerData?.address,
        }),
        [props.customerData],
    );

    useEffect(() => {
        // performance api is pretty mature and widely supported for a long time by desktop and mobile browsers
        // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
        const navigation = performance.getEntriesByType('navigation').shift() as PerformanceNavigationTiming | undefined;

        const reloaded = navigation?.type === 'reload';
        const authenticated = state === 'authenticated';

        const storedStep = sessionStorage.retrieve<Step>(REGISTRATION_CONTEXT_KEY);

        if ((reloaded || authenticated) && storedStep) {
            // customer refreshed the page for some reason so we restore previous step or customer did setup sms/pin/email but did some back and forth navigation
            setStep(storedStep);
        } else {
            sessionStorage.store(REGISTRATION_CONTEXT_KEY);

            // if the step is missing and state is authenticated we consider the customer is registered successfully
            if (authenticated) {
                props.onRegister?.();
            } else {
                changeStep({ type: 'phone' });
            }
        }

        return () => {
            sessionStorage.store(REGISTRATION_CONTEXT_KEY);
            clearHeaderSlot('left');
            clearHeaderSlot('right');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (step?.type === 'info' && props.onBack) {
            setHeaderSlot('left', <BackButton onClick={() => props.onBack?.()} />);
        } else if (step?.type && ['phone', 'phone-verify', 'pin', 'code'].includes(step.type)) {
            clearHeaderSlot('left');
        }

        if (step?.type) {
            props.onStepChange?.(step.type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step?.type]);

    const changeStep = (target: Step) => {
        sessionStorage.store(REGISTRATION_CONTEXT_KEY, target);
        setStep(target);
    };

    const customerSetupCompleted = () => {
        sessionStorage.store(REGISTRATION_CONTEXT_KEY);
        changeStep({ type: 'code' });
    };

    const infoCompleted = () => {
        changeStep({ type: 'pin' });
    };

    const handlePhoneInput = (phone: string) => {
        // Check if the phone number has changed
        if (phone !== currentEnteredPhone) {
            setCurrentEnteredPhone(null);
        }

        // Check if the phone number is German and handle overlay logic
        const isGermanNumber = phone.startsWith('+49');
        if (!isGermanNumber && currentEnteredPhone !== phone) {
            setEnteredPhone(phone);
            setCurrentEnteredPhone(phone);
            setShowOverlay(true);
            return; // Stop further processing
        }

        // If validation passes, proceed with phone verification
        setShowOverlay(false);
        startPhoneVerification(phone);
    };

    const startPhoneVerification = async (phone: string) => {
        try {
            if (!session || (session.type === 'Customer' && session.scope !== 'NewCustomer')) {
                await startCustomerSession('NewCustomer', 'Phone', phone);
            }

            await createOtpAuthFactor('Phone', phone, i18n.language);
            changeStep({ type: 'phone-verify', phone });
        } catch (error) {
            if (
                (isResponseError(error, 'InitialVerificationPendingForCustomer') && props.handleIncompleteVerification) ||
                isResponseError(error, 'PhoneNumberIsRegisteredForSameCustomerAndSession')
            ) {
                await startExistingVerification(phone);
            } else {
                changeStep({ type: 'phone', error: ensureTranslatableError(error) });
            }
        }
    };

    const startExistingVerification = async (phone: string) => {
        try {
            await generateOtpCode('Phone', phone, i18n.language);
            changeStep({ type: 'phone-verify', phone, initialVerificationPending: true });
        } catch (error) {
            changeStep({ type: 'phone', error: ensureTranslatableError(error) });
        }
    };

    const completePhoneVerification = (initialVerificationPending?: boolean) => {
        if (initialVerificationPending) {
            changeStep({ type: 'code' });
        } else {
            changeStep({ type: 'info' });
        }
    };

    const registrationCompleted = () => {
        props.onRegister?.();
    };

    const pinSetupCompleted = () => {
        changeStep({ type: 'account' });
    };

    const accountStepChanged = (step: SubStep) => {
        changeStep({ type: 'account', step });
    };

    const registrationFailed = () => {
        sessionStorage.store(REGISTRATION_CONTEXT_KEY);
        logout();
        props.onBack?.();
    };

    return (
        <>
            {step?.type === 'phone' && (
                <PhoneInput
                    onInput={handlePhoneInput}
                    onAction={props.onLogin}
                    error={step.error && <ErrorTrans t={t} error={step.error} additionalContext="Phone" />}
                    layout={props.compact ? 'next' : 'register'}
                />
            )}
            {step?.type === 'phone-verify' && (
                <PhoneSetup phone={step.phone} onComplete={() => completePhoneVerification(step.initialVerificationPending)} />
            )}
            {step?.type === 'info' && <InformationPage onContinue={infoCompleted} note={props.merchant ? 'shops' : 'existingCustomer'} />}
            {step?.type === 'pin' && <PinSetup onComplete={pinSetupCompleted} />}
            {step?.type === 'account' && (
                <AccountSetup
                    defaultValues={defaultAccountValues}
                    merchant={props.merchant}
                    defaultStep={step.step}
                    onError={registrationFailed}
                    onChange={accountStepChanged}
                    onComplete={customerSetupCompleted}
                    onBack={() => {
                        setStep({ type: 'info' });
                        setHeaderSlot('left', <BackButton onClick={() => props.onBack?.()} />);
                    }}
                />
            )}
            {step?.type === 'code' && <CodePage onComplete={registrationCompleted} />}

            <Dialog
                open={showOverlay}
                onOpenChange={() => setShowOverlay(false)}
                align="bottom"
                dismissible={false}
                title={t('residenceInfoDialog.title')}
                icon={
                    <div className={classes.flagWrapper}>
                        <ReactCountryFlag countryCode="DE" svg className={classes.flag} />
                    </div>
                }
                actions={
                    <>
                        <Button onClick={() => setShowOverlay(false)} size="large" appearance="outline">
                            {t('residenceInfoDialog.cancel')}
                        </Button>
                        <Button onClick={() => handlePhoneInput(enteredPhone)} size="large" appearance="primary">
                            {t('residenceInfoDialog.proceed')}
                        </Button>
                    </>
                }
            >
                <Body block>{t('residenceInfoDialog.body')}</Body>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles({
    flagWrapper: {
        borderRadius: '50%',
        overflow: 'hidden',
        width: '50px',
        height: '50px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flag: {
        height: '100% !important',
        width: 'auto !important',
    },
});
