import { makeStyles } from '@fluentui/react-components';
import { QrCodeFilled } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { Body, Dialog } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { cancel, getList } from './api';
import { DuplicateTransactionIntentItem } from './DuplicateTransactionIntentItem';
import { TransactionIntent } from './models';

type DuplicateTransactionIntentDialogProps = {
    show: boolean;
    onBack: () => void;
    onError: (e: string) => void;
    onContinue: () => void;
};

export const DuplicateTransactionIntentDialog: React.FC<DuplicateTransactionIntentDialogProps> = ({
    show,
    onBack,
    onError,
    onContinue,
}: DuplicateTransactionIntentDialogProps) => {
    const classes = useStyles();

    const { state, customerId } = useAuth();
    const { t } = useTranslation('portal');
    const navigate = useNavigate();

    const [transactionIntents, setTransactionIntents] = useState<TransactionIntent[]>();

    useEffect(() => {
        if (state === 'authenticated' && show) {
            getList({ customerId, type: 'ActiveByCustomer' })
                .then((intents) => {
                    const items = intents.items.filter(
                        (intent) => (intent.state === 'Pending' || intent.state === 'PendingApproval') && !intent.merchantId,
                    );
                    setTransactionIntents(items);
                    if (!items.length) {
                        onContinue();
                    }
                })
                .catch(onError);
        }
    }, [state, customerId, navigate, show, onContinue, onError]);

    const cancelIntentAndProceed = () =>
        cancel(transactionIntents![0].id)
            .then(() => {
                setTransactionIntents([]);
                onContinue();
            })
            .catch(onError);

    return (
        <Dialog
            open={show && !!transactionIntents?.length}
            onOpenChange={onBack}
            align="bottom"
            dismissible={true}
            icon={<QrCodeFilled />}
            title={
                <>
                    <p>{t('transactionIntent.duplicate.title')}</p>
                    <span className={classes.subtitle}>{t('transactionIntent.duplicate.subtitle')}</span>
                </>
            }
            actions={[
                { text: t('transactionIntent.duplicate.backToHome'), onClick: onBack },
                {
                    text: t('transactionIntent.duplicate.cancelAndProceed'),
                    style: 'preferred',
                    onClick: cancelIntentAndProceed,
                },
            ]}
        >
            <Body className={classes.content}>
                {transactionIntents?.map((transactionIntent) => (
                    <DuplicateTransactionIntentItem key={transactionIntent.id} transactionIntent={transactionIntent} />
                ))}
            </Body>
        </Dialog>
    );
};

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: tokens.spacingVerticalL,
        paddingRight: tokens.spacingVerticalL,
        alignItems: 'center',
    },
    subtitle: {
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase400,
        margin: `-10px`,
    },
    info: {
        width: '100%',
        textAlign: 'center',
    },
});
