import { lazy, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { useCookieConsent } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { CurrentKycRequest } from '@zastrpay/kyc-requests';
import { Footer } from '@zastrpay/layout';
import { Login } from '@zastrpay/login';

import { identifyUser, resetUser } from './common/application-insights';
import { GeneralError } from './common/GeneralError';
import { NetworkError } from './common/NetworkError';
import { SessionExpired } from './common/SessionExpired';
import { Support } from './common/Support';
import { featureToggles } from './config';
import { RegistrationFinalize } from './customer/RegistrationFinalize';
import { VerifyEmail } from './customer/VerifyEmail';
import { Home } from './home/Home';
import { AuthenticatedLayout } from './layout/AuthenticatedLayout';
import { Layout } from './layout/Layout';
import { PersonalDetails } from './settings/PersonalDetails';
import { Settings } from './settings/Settings';
import { TrustedBeneficiaryList } from './settings/TrustedBeneficiaryList';
import { TransactionList } from './transactions/TransactionList';

const AnswerQuestionnaire = lazy(() => import('@zastrpay/kyc-questionnaires').then((module) => ({ default: module.AnswerQuestionnaire })));
const ChangeEmail = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangeEmail })));
const ChangePhone = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangePhone })));
const ChangeAuthFactorQrCodePage = lazy(() =>
    import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangeAuthFactorQrCodePage })),
);
const ChangePin = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangePin })));
const ChangeEmailSettings = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangeEmailSettings })));
const ChangePhoneSettings = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangePhoneSettings })));
const ChangePinSettings = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.ChangePinSettings })));
const DocumentUpload = lazy(() => import('@zastrpay/kyc-documents').then((module) => ({ default: module.DocumentUpload })));
const Registration = lazy(() => import('@zastrpay/registration').then((module) => ({ default: module.Registration })));
const ShopList = lazy(() => import('@zastrpay/shops').then((module) => ({ default: module.ShopList })));
const TransactionIntentCreation = lazy(() =>
    import('@zastrpay/transaction-intents').then((module) => ({ default: module.TransactionIntentCreation })),
);

export const App: React.FC = () => {
    const navigate = useNavigate();

    const { state, customerId, clear, logout, customer } = useAuth();
    const { openCookieBanner, analyticsEnabled, cookieConsent } = useCookieConsent();

    const registrationConfig = useMemo(() => {
        return {
            // we do not provide onRegister, in the moment the registration is complete the customer object is
            // loaded and the routes are replaced with redirects and we do not have to do anything this needs
            // to be analyzed deeper for a nicer and more straight forward solution in the future
            // onRegister: () => {}
            onLogin: () => {
                clear();
                logout();
                navigate('/login', { replace: true });
            },
            onBack: () => {
                clear();
                logout();
                navigate('/login');
            },
        };
    }, [clear, logout, navigate]);

    const loginConfig = useMemo(() => {
        return {
            // we do not provide onLogin, in the moment the login is complete the customer object is loaded
            // and the routes are replaced with redirects and we do not have to do anything this needs to be
            // analyzed deeper for a nicer and more straight forward solution in the future
            // login: () => {}
            onRegister: () => {
                clear();
                logout();
                navigate('/register', { replace: true });
            },
            onPinChange: () => navigate('/change-pin'),
            onPhoneChange: () => navigate('/change-phone'),
            onEmailChange: () => navigate('/change-email'),
        };
    }, [clear, logout, navigate]);

    useEffect(() => {
        if (state === 'expired') {
            logout();
        }
    }, [state, logout]);

    useEffect(() => {
        if (cookieConsent?.types?.includes('Essential')) {
            identifyUser(customerId);
        } else {
            resetUser();
        }
    }, [cookieConsent, customerId]);

    return (
        <Routes>
            {/* Layout handles Suspense for the lazy loading so don't add it again in the routes */}
            <Route element={<Layout />}>
                <Route
                    path="login"
                    element={
                        customer ? (
                            <Navigate to="/" replace />
                        ) : (
                            <>
                                <Login {...loginConfig} />
                                <Footer enable={analyticsEnabled} openCookieBanner={openCookieBanner} />
                            </>
                        )
                    }
                />
                <Route path="login/*" element={<Navigate to="/login" replace />} />

                <Route path="register/finalize" element={<RegistrationFinalize />} />
                <Route
                    path="register"
                    element={
                        customer ? (
                            <Navigate to="/" replace />
                        ) : (
                            <>
                                <Registration {...registrationConfig} />
                                <Footer enable={analyticsEnabled} openCookieBanner={openCookieBanner} />
                            </>
                        )
                    }
                />
                <Route
                    path="change-phone"
                    element={<ChangePhone onChange={() => navigate('/')} onPinChange={() => navigate('/change-pin')} />}
                />
                <Route path="change-pin" element={<ChangePin onChange={() => navigate('/')} />} />
                <Route path="change-email" element={<ChangeEmail onChange={() => navigate('/')} />} />
                <Route path="verify-email" element={<VerifyEmail />} />
                <Route path="account-recovery/:authFlowId" element={<ChangeAuthFactorQrCodePage />} />

                <Route path="support" element={<Support />} />

                <Route path="error">
                    <Route index element={<GeneralError />} />
                    <Route path="network" element={<NetworkError />} />
                    <Route path="expired" element={<SessionExpired />} />
                </Route>
            </Route>

            <Route element={<AuthenticatedLayout bottomMenu userMenu walletActions />}>
                <Route index element={<Home />} />
            </Route>

            <Route element={<AuthenticatedLayout bottomMenu userMenu />}>
                <Route path="transactions" element={<TransactionList />} />
                <Route path="shops" element={<ShopList />} />
                <Route path="transaction-intent">
                    <Route
                        path="deposit"
                        element={
                            <TransactionIntentCreation
                                intentType="CustomerDeposit"
                                onBack={() => navigate('/')}
                                onComplete={() => navigate('/')}
                                onFailure={() => navigate('/')}
                                onCancelled={() => navigate('/')}
                                onSuccess={() => navigate('/')}
                                // TODO: remove feature toggle after transaction monitors testing
                                shouldCheckLimits={featureToggles.limitsCheckEnabled}
                            />
                        }
                    />
                    <Route
                        path="withdraw"
                        element={
                            <TransactionIntentCreation
                                intentType="CustomerWithdrawal"
                                onBack={() => navigate('/')}
                                onComplete={() => navigate('/')}
                                onFailure={() => navigate('/')}
                                onCancelled={() => navigate('/')}
                                onSuccess={() => navigate('/')}
                                // TODO: remove feature toggle after transaction monitors testing
                                shouldCheckLimits={featureToggles.limitsCheckEnabled}
                            />
                        }
                    />
                </Route>
            </Route>

            <Route path="settings" element={<AuthenticatedLayout onCancel={() => navigate('/')} />}>
                <Route index element={<Settings />} />
                <Route path="change-phone" element={<ChangePhoneSettings onChange={() => navigate('/')} />} />
                <Route path="change-pin" element={<ChangePinSettings onChange={() => navigate('/')} />} />
                <Route path="change-email" element={<ChangeEmailSettings onChange={() => navigate('/')} />} />
                <Route path="trusted-beneficiaries" element={<TrustedBeneficiaryList />} />
                <Route path="personal-details" element={<PersonalDetails />} />
            </Route>

            <Route path="kyc-request" element={<AuthenticatedLayout />}>
                <Route path=":id" element={<CurrentKycRequest />}>
                    <Route
                        path="document-upload"
                        element={<DocumentUpload onBack={() => navigate('/')} onComplete={() => navigate('/')} />}
                    />
                    <Route path="questionnaire" element={<AnswerQuestionnaire onComplete={() => navigate('/')} />} />
                    <Route path="verify-email" element={<VerifyEmail />} />
                </Route>
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
