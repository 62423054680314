import { makeStyles } from '@fluentui/react-components';
import { CheckmarkCircleRegular, DismissCircleRegular } from '@fluentui/react-icons';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { validate as validateUuid } from 'uuid';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import * as api from './api';
import { ChangeAuthFactorQrCode } from './ChangeAuthFactorQrCode';
import { useHubListener } from './hub';
import { AuthFlow } from './models';

const supportedAuthFactor = (authFlow: AuthFlow | undefined) => {
    switch (authFlow?.type) {
        case 'ChangePhoneFaceToFace':
            return 'phone';
        case 'ChangeEmailFaceToFace':
            return 'email';
        case 'ChangeEmail':
        case 'ChangePhone':
        case 'ChangePin':
        case undefined:
            return;
    }
};

type QrCodeStep = {
    type: 'qrCode';
    authFactor: 'phone' | 'email';
    authFlow: AuthFlow;
};

type ErrorStep = {
    type: 'error';
};

type SuccessStep = {
    type: 'success';
    authFactor: 'phone' | 'email';
};

type Step = QrCodeStep | ErrorStep | SuccessStep;

export const ChangeAuthFactorQrCodePage: React.FC = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { t } = useTranslation('auth-flow');

    const { authFlowId } = useParams();
    const [step, setStep] = useState<Step>();

    const getCurrentStep = useCallback((authFlow: AuthFlow): Step => {
        const authFactor = supportedAuthFactor(authFlow);

        if (!authFactor) {
            return { type: 'error' };
        }

        if (authFlow.state === 'PendingChallenges' || authFlow.state === 'PendingChange') {
            return { type: 'qrCode', authFactor, authFlow };
        } else if (authFlow.state === 'Completed') {
            return { type: 'success', authFactor };
        } else {
            return { type: 'error' };
        }
    }, []);

    const fetchAuthFlow = useCallback(
        (authFlowId: string) =>
            api
                .getAuthFlow(authFlowId)
                .then((authFlow) => {
                    const currentStep = getCurrentStep(authFlow);

                    setStep(currentStep);
                })
                .catch(() => {
                    setStep({ type: 'error' });
                }),
        [getCurrentStep],
    );

    useEffect(() => {
        if (!authFlowId || !validateUuid(authFlowId)) {
            navigate('/');
        } else {
            fetchAuthFlow(authFlowId);
            trackPage('change_auth_factor_qr_code');
        }
    }, [fetchAuthFlow, navigate, authFlowId]);

    useHubListener('AuthFlowEvents', (event) => {
        if (event.authFlowId !== authFlowId || step?.type !== 'qrCode') {
            return;
        }

        if (event.state === 'AuthFlowCancelled') {
            setStep({ type: 'error' });
        } else if (event.state === 'AuthFlowCompleted') {
            setStep({ type: 'success', authFactor: step.authFactor });
        }
    });

    const trackFindShops = () => {
        trackClick('change_auth_factor_qr_code', 'find_shops');
    };

    switch (step?.type) {
        case 'qrCode':
            return (
                <ChangeAuthFactorQrCode
                    authFactor={step.authFactor}
                    authFlowId={step.authFlow.id}
                    onFindShopsClick={trackFindShops}
                ></ChangeAuthFactorQrCode>
            );
        case 'success':
            return (
                <MessagePage
                    icon={<CheckmarkCircleRegular className={classes.successIcon} />}
                    title={t('qrCode.pages.success.title', { context: step.authFactor })}
                    message={t('qrCode.pages.success.message', { context: step.authFactor })}
                    action={t('qrCode.pages.goToLogin')}
                    onAction={() => navigate('/login')}
                />
            );
        case 'error':
            return (
                <MessagePage
                    icon={<DismissCircleRegular />}
                    title={t('qrCode.pages.error.title')}
                    message={t('qrCode.pages.error.message')}
                    action={t('qrCode.pages.goToLogin')}
                    onAction={() => navigate('/login')}
                />
            );
        case undefined:
            return null;
    }
};

const useStyles = makeStyles({
    link: {
        fontWeight: tokens.fontWeightSemibold,
    },
    successIcon: {
        color: tokens.customPaletteGreen,
    },
});
