import { CookiesRegular } from '@fluentui/react-icons';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Body, Dialog, Link } from '@zastrpay/components';

import { analyticsConfig } from './config';
import { useCookieConsent } from './CookieConsentProvider';
import { CookieSettings } from './CookieSettings';
import { PrivacyPolicyDialog } from './legal/PrivacyPolicyDialog';
import { ConsentType, CookieConsent } from './model';

export const CookieBanner: React.FC = () => {
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [privacyVisible, setPrivacyVisible] = useState(false);
    const { cookieConsent, setConsents, showBanner, setShowBanner } = useCookieConsent();

    const { t } = useTranslation('analytics');

    const accept = (consentTypes: ConsentType[]) => {
        const consent: CookieConsent = {
            types: consentTypes,
            date: new Date(),
            submitted: false,
        };

        setConsents(consent);
        setShowBanner(false);
        setShowSettings(false);
    };

    return (
        analyticsConfig.enabled && (
            <>
                <Dialog
                    modalType="non-modal"
                    open={showBanner}
                    align="bottom"
                    dismissible={false}
                    title={!showSettings ? t('cookieConsent.banner.title') : t('cookieConsent.settings.title')}
                    icon={!showSettings && <CookiesRegular />}
                    actions={
                        !showSettings && [
                            { text: t('cookieConsent.banner.manage'), onClick: () => setShowSettings(true) },
                            {
                                text: t('cookieConsent.banner.acceptAll'),
                                style: 'preferred',
                                onClick: () => accept(['Essential', 'Analytic']),
                            },
                        ]
                    }
                >
                    {!showSettings ? (
                        <Body block>
                            <Trans
                                t={t}
                                i18nKey="cookieConsent.banner.description"
                                components={{
                                    policyLink: <Link inline onClick={() => setPrivacyVisible(true)} />,
                                }}
                            />
                        </Body>
                    ) : (
                        <CookieSettings
                            consents={cookieConsent?.types}
                            onAccept={accept}
                            onOpenPrivacyPolicy={() => setPrivacyVisible(true)}
                        />
                    )}
                </Dialog>
                <PrivacyPolicyDialog open={privacyVisible} onOpenChange={setPrivacyVisible} />
            </>
        )
    );
};
