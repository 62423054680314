import { makeStyles, shorthands, Subtitle2Stronger } from '@fluentui/react-components';
import { ReactNode } from 'react';

import { Body, Button } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

type ChangeOptionProps = {
    icon: ReactNode;
    title: string;
    description: string;
    buttonText: string;
    onClick: () => void;
};

export const ChangeOption: React.FC<ChangeOptionProps> = ({ icon, title, description, buttonText, onClick }) => {
    const classes = useOptionStyles();
    return (
        <div className={classes.option}>
            <div className={classes.textContainer}>
                <Subtitle2Stronger block align="start">
                    {title}
                </Subtitle2Stronger>
                <div className={classes.icon}>{icon}</div>
            </div>
            <Body block align="start" className={classes.description}>
                {description}
            </Body>
            <Button size="large" appearance="outline" onClick={onClick}>
                {buttonText}
            </Button>
        </div>
    );
};

const useOptionStyles = makeStyles({
    option: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        padding: tokens.spacingHorizontalXL,
        borderRadius: tokens.borderRadiusXLarge,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow8,
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    icon: {
        fontSize: tokens.fontSizeHero900,
        color: tokens.colorBrandForeground1,
    },
    description: {
        ...shorthands.margin(tokens.spacingHorizontalL, tokens.spacingHorizontalNone),
    },
});
