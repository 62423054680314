import { lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentKycRequest, useKycRequest } from '@zastrpay/kyc-requests';
import { BackButton, useLayout } from '@zastrpay/layout';

const RegistrationCode = lazy(() => import('@zastrpay/registration').then((module) => ({ default: module.RegistrationCode })));

export const RegistrationFinalize: React.FC = () => {
    const navigate = useNavigate();
    const { setHeaderSlot, clearHeaderSlot } = useLayout();
    const { submit, complete } = useKycRequest();

    const { currentKycRequest: kycRequest } = useCurrentKycRequest();

    if (!kycRequest) {
        throw new Error('KycRequest is required');
    }

    const completeRegistration = () => {
        submit(kycRequest);
        complete(kycRequest);

        navigate('/');
    };

    useEffect(() => {
        setHeaderSlot('left', <BackButton onClick={() => navigate(-1)} />);

        return () => clearHeaderSlot('left');
    }, [navigate, setHeaderSlot, clearHeaderSlot]);

    return <RegistrationCode onComplete={completeRegistration} />;
};
