import React, { lazy, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { KycRequestPage, useCurrentKycRequest, useKycRequest } from '@zastrpay/kyc-requests';

const VerifyEmailComponent = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.VerifyEmail })));

export const VerifyEmail: React.FC = () => {
    const navigate = useNavigate();
    const { submit, complete } = useKycRequest();

    const { currentKycRequest: kycRequest } = useCurrentKycRequest();

    if (!kycRequest) {
        throw new Error('KycRequest is required');
    }

    const emailVerified = useCallback(() => {
        submit(kycRequest);
        complete(kycRequest);

        navigate('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycRequest]);

    return (
        <KycRequestPage kycRequest={kycRequest} onSkip={() => navigate('/')} skippable>
            <VerifyEmailComponent onVerify={emailVerified} />
        </KycRequestPage>
    );
};
