import { AxiosHeaders } from 'axios';

import { api, PagedList, sanitize, Serialized, Time } from '@zastrpay/common';

import { CUSTOMER_KYC_CMDH, CUSTOMER_KYC_QRYH } from './config';
import { KycRequest, KycRequestState, SubmitDataRequest } from './models';

const headers = new AxiosHeaders().setAccept('application/json; charset=UTF-8; version=v1.0-p', true);

const deserializeKycRequest = (request: Serialized<KycRequest>): KycRequest => ({
    ...request,
    dueOn: request.dueOn ? new Date(request.dueOn) : undefined,
    dueAfter: request.dueAfter ? new Time(request.dueAfter) : undefined,
    createdOn: new Date(request.createdOn),
    lastModifiedOn: new Date(request.lastModifiedOn),
});

type FilterParams = {
    _limit: number;
    _order: string;
    queryType: string;
    customerId: string;
    state?: KycRequestState[];
};

export const getList = (customerId: string): Promise<KycRequest[]> => {
    const params: FilterParams = {
        // db index guarantees at most one active kyc request of each type per customer, thus 100 is safe limit that should never be reached
        _limit: 100,
        _order: 'asc',
        customerId,
        queryType: 'ByCustomerAndState',
        state: ['Pending', 'PendingOverdue', 'PendingManualReview', 'DataRejected'],
    };

    return api
        .get<PagedList<KycRequest>>(`${CUSTOMER_KYC_QRYH}/kyc-requests`, { headers, params: sanitize(params) })
        .then((data) => data.items.map(deserializeKycRequest));
};

export const submitData = (id: string, data: SubmitDataRequest): Promise<KycRequest> => {
    return api
        .post<KycRequest>(`${CUSTOMER_KYC_CMDH}/kyc-requests/${id}/data`, sanitize(data), { headers, loader: false })
        .then(deserializeKycRequest);
};

export const skip = (id: string): Promise<KycRequest> => {
    return api
        .post<KycRequest>(`${CUSTOMER_KYC_CMDH}/kyc-requests/${id}/skip`, undefined, { headers, loader: false })
        .then(deserializeKycRequest);
};
