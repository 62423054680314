import { Card, makeStyles, Subtitle1, Switch } from '@fluentui/react-components';
import { CalendarDataBarRegular, CheckmarkCircleRegular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Body, Button, Link } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { ConsentType } from './model';

export type CookieSettingsProps = {
    consents?: ConsentType[];
    onAccept?: (consents: ConsentType[]) => void;
    onOpenPrivacyPolicy?: () => void;
};

export const CookieSettings: React.FC<CookieSettingsProps> = (props: CookieSettingsProps) => {
    const [analyticsEnabled, setAnalyticsEnabled] = useState<boolean>(props.consents?.includes('Analytic') ?? true);
    const { t } = useTranslation('analytics');
    const classes = useStyles();

    const accept = () => {
        const consentTypes: ConsentType[] = ['Essential'];
        if (analyticsEnabled) {
            consentTypes.push('Analytic');
        }
        props.onAccept?.(consentTypes);
    };

    return (
        <>
            <Body block>
                <Trans
                    t={t}
                    i18nKey="cookieConsent.settings.description"
                    components={{
                        policyLink: <Link inline onClick={props.onOpenPrivacyPolicy} />,
                    }}
                />
            </Body>
            <div className={classes.settingsOptions}>
                <Card appearance="outline">
                    <div className={classes.settingOption}>
                        <CheckmarkCircleRegular className={classes.settingIcon} />
                        <div className={classes.settingDescription}>
                            <Subtitle1>{t('cookieConsent.settings.essentialCookies.title')}</Subtitle1>
                            <Body align="start">{t('cookieConsent.settings.essentialCookies.description')}</Body>
                        </div>
                        <Switch disabled={true} checked={true} indicator={{ className: classes.disabledIndicator }} />
                    </div>
                </Card>

                <Card appearance="outline">
                    <div className={classes.settingOption}>
                        <CalendarDataBarRegular className={classes.settingIcon} />
                        <div className={classes.settingDescription}>
                            <Subtitle1>{t('cookieConsent.settings.analyticCookies.title')}</Subtitle1>
                            <Body align="start">{t('cookieConsent.settings.analyticCookies.description')}</Body>
                        </div>
                        <Switch
                            checked={analyticsEnabled}
                            onChange={(ev, data) => setAnalyticsEnabled(data.checked)}
                            indicator={{ className: classes.indicator }}
                        />
                    </div>
                </Card>
            </div>
            <div className={classes.actions}>
                <Button size="large" appearance="primary" onClick={accept}>
                    {t('cookieConsent.settings.accept')}
                </Button>
            </div>
        </>
    );
};

const useStyles = makeStyles({
    actions: {
        marginTop: tokens.spacingVerticalXXXL,
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingHorizontalL,
    },
    settingsOptions: {
        marginTop: tokens.spacingVerticalXL,
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalL,
    },
    settingIcon: {
        width: '30px',
        height: '30px',
        flexBasis: '30px',
        flexGrow: 0,
        flexShrink: 0,
    },
    settingOption: {
        display: 'flex',
        flexDirection: 'row',
        gap: tokens.spacingVerticalL,
    },
    settingDescription: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
    },
    disabledIndicator: {
        opacity: 0.44,
        '--colorNeutralBackgroundDisabled': tokens.customPaletteGreen,
        '--colorNeutralForegroundDisabled': tokens.colorNeutralForegroundStaticInverted,
    },
    indicator: {
        '--colorCompoundBrandBackground': tokens.customPaletteGreen,
        '--colorCompoundBrandBackgroundHover': tokens.customPaletteGreenDark,
        '--colorCompoundBrandBackgroundPressed': tokens.customPaletteGreenDark,
    },
});
