import { makeStyles } from '@fluentui/react-components';
import { DocumentTextClockRegular, ErrorCircleRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { Body, Button, Divider } from '@zastrpay/components';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { KycRequest } from './models';

type RequiredSummaryStep = {
    type: 'required-summary';
};

type PendingManualReviewStep = {
    type: 'pending-manual-review';
};

type NextKycRequestStep = {
    type: 'next-kyc';
    kycRequest: KycRequest;
};

type Step = RequiredSummaryStep | PendingManualReviewStep | NextKycRequestStep;

export type TransactionKycRequestProps = {
    kycRequest: KycRequest;
    allKycRequests: KycRequest[];
    suggestedAmount?: number;
    merchantName?: string;
    onRetryClick: () => void;
};

export const getKycRequestUrl = (kycRequest: KycRequest) => {
    switch (kycRequest.type) {
        case 'EmailVerification':
            return `/kyc-request/${kycRequest.id}/verify-email`;
        case 'InitialVerification':
            return `/kyc-request/${kycRequest.id}/finalize-registration`;
        case 'PoaDocument':
        case 'PoiDocument':
        case 'SofDocument':
        case 'SowDocument':
            return `/kyc-request/${kycRequest.id}/document-upload`;
        case 'SofQuestionnaire':
        case 'SowQuestionnaire':
        case 'SelfDeclaredLinksQuestionnaire':
        case 'SelfDeclaredPepQuestionnaire':
            return `/kyc-request/${kycRequest.id}/questionnaire`;
    }
};

export const TransactionKycRequest: React.FC<TransactionKycRequestProps> = ({
    kycRequest,
    allKycRequests,
    suggestedAmount,
    merchantName,
    onRetryClick,
}) => {
    const classes = useStyles();
    const { t } = useTranslation('kyc-requests');

    const [step, setStep] = useState<Step>();

    useEffect(() => {
        if (kycRequest.state === 'PendingManualReview') {
            setStep({ type: 'pending-manual-review' });
        } else {
            setStep({ type: 'required-summary' });
        }
    }, [kycRequest.state]);

    switch (step?.type) {
        case 'required-summary':
            return (
                <MessagePage
                    icon={<ErrorCircleRegular className={classes.overdue} />}
                    title={t('kycRequest.requiredSummary.title')}
                    message={
                        <>
                            <Body>{t('kycRequest.requiredSummary.mainDescription')}</Body>
                            <ul className={classes.list}>
                                {allKycRequests.map(({ type }) => (
                                    <li key={type}>{t('kycRequests.title', { context: type })}</li>
                                ))}
                            </ul>
                        </>
                    }
                    action={t('kycRequest.requiredSummary.mainAction')}
                    onAction={() => setStep({ type: 'next-kyc', kycRequest })}
                >
                    {suggestedAmount && (
                        <>
                            <Divider>{t('kycRequest.requiredSummary.divider')}</Divider>
                            <Body>
                                <Trans
                                    t={t}
                                    i18nKey="kycRequest.requiredSummary.secondaryDescription"
                                    values={{ amount: suggestedAmount }}
                                />
                            </Body>
                            <Button size="large" appearance="outline" onClick={onRetryClick}>
                                {t('kycRequest.requiredSummary.secondaryAction', {
                                    amount: suggestedAmount,
                                    ...(merchantName ? { context: 'merchant', merchant: merchantName } : {}),
                                })}
                            </Button>
                        </>
                    )}
                </MessagePage>
            );
        case 'pending-manual-review':
            const merchantTranslationContext = merchantName ? { merchant: merchantName } : { context: 'GenericMerchant' };

            return (
                <MessagePage
                    icon={<DocumentTextClockRegular />}
                    title={t('kycRequest.documentReview.title')}
                    message={
                        <>
                            <Body block>{t('kycRequest.documentReview.message')}</Body>
                            {suggestedAmount && (
                                <div className={classes.text}>
                                    <Trans
                                        t={t}
                                        i18nKey="kycRequest.documentReview.secondaryMessage"
                                        values={{ amount: suggestedAmount }}
                                    />
                                </div>
                            )}
                        </>
                    }
                    action={
                        suggestedAmount
                            ? t('kycRequest.documentReview.retryAmount', {
                                  amount: suggestedAmount,
                                  ...merchantTranslationContext,
                              })
                            : t('kycRequest.documentReview.back', merchantTranslationContext)
                    }
                    onAction={onRetryClick}
                />
            );
        case 'next-kyc':
            return <Navigate to={getKycRequestUrl(kycRequest)} replace />;
        case undefined:
            return null;
    }
};

const useStyles = makeStyles({
    overdue: {
        color: tokens.customPaletteRed,
    },
    list: {
        textAlign: 'left',
        marginBottom: tokens.spacingVerticalNone,
    },
    text: {
        marginTop: tokens.spacingVerticalL,
    },
});
