import { AxiosHeaders } from 'axios';

import { api, sanitize } from '@zastrpay/common';

import { LIMIT_SVC_CMDH } from './config';
import { ReserveAccrualRequest } from './models';

const headers = new AxiosHeaders().setAccept('application/json; charset=UTF-8; version=v1.0-p', true);

export const reserveAccrual = (limitAccountSetId: string, sessionId: string, data: ReserveAccrualRequest): Promise<unknown> => {
    return api.put(`${LIMIT_SVC_CMDH}/limit-account-sets/${limitAccountSetId}/accruals/${sessionId}`, sanitize(data), {
        headers,
        loader: false,
    });
};
