import { makeStyles } from '@fluentui/react-components';
import { BuildingShopRegular, GlobeArrowUpRegular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import { Body, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { ChangeOption } from './ChangeOption';

type ChangePhoneOptionsProps = {
    onShopChosen: () => void;
    onOnlineChosen: () => void;
};

export const ChangePhoneOptions: React.FC<ChangePhoneOptionsProps> = ({ onShopChosen, onOnlineChosen }) => {
    const { t } = useTranslation('auth-flow');
    const classes = useStyles();

    return (
        <Page className={classes.page}>
            <Title>{t('changePhoneOptions.title')}</Title>
            <Body>{t('changePhoneOptions.description')}</Body>
            <div className={classes.optionContainer}>
                <ChangeOption
                    icon={<BuildingShopRegular />}
                    title={t('changePhoneOptions.changeInShop.title')}
                    description={t('changePhoneOptions.changeInShop.description')}
                    buttonText={t('changePhoneOptions.changeInShop.button')}
                    onClick={onShopChosen}
                />
                <ChangeOption
                    icon={<GlobeArrowUpRegular />}
                    title={t('changePhoneOptions.changeOnline.title')}
                    description={t('changePhoneOptions.changeOnline.description')}
                    buttonText={t('changePhoneOptions.changeOnline.button')}
                    onClick={onOnlineChosen}
                />
            </div>
        </Page>
    );
};

const useStyles = makeStyles({
    page: {
        padding: tokens.spacingVerticalXL,
        backgroundColor: tokens.colorNeutralBackground2,
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalXXL,
        marginTop: tokens.spacingVerticalXL,
    },
});
