import { makeStyles } from '@fluentui/react-components';
import { MailReadRegular, MailRegular } from '@fluentui/react-icons';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { trackClick, trackInput, trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, ResponseError } from '@zastrpay/common';
import { Alert, Body, Dialog, ErrorTrans, Link, Title } from '@zastrpay/components';
import { useRequestLoader } from '@zastrpay/hooks';
import { Page } from '@zastrpay/layout';
import { OtpInput, OtpInputElement } from '@zastrpay/pages';
import { tokens } from '@zastrpay/theme';

export type EmailOtpInputProps = {
    email: string;
    onVerify?: () => void;
    onChange?: () => void;
    onNoAccess?: () => void;
};

const SHOW_OVERLAY_AFTER_ERRORS = 2;

export const EmailOtpInput: React.FC<EmailOtpInputProps> = (props) => {
    const classes = useStyles();
    const loading = useRequestLoader();
    const otpRef = useRef<OtpInputElement>(null);

    const { t, i18n } = useTranslation('auth-flow');
    const { verifyOtpCode, generateOtpCode } = useAuth();

    const [error, setError] = useState<ResponseError>();
    const [showHint, setShowHint] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [errorOrResendCount, setErrorOrResendCount] = useState(0);

    useEffect(() => {
        trackPage('email_verify');
    }, []);

    const tryShowOverlay = () => {
        if (errorOrResendCount === SHOW_OVERLAY_AFTER_ERRORS) {
            setShowOverlay(true);
        }
        setErrorOrResendCount((errorCount) => errorCount + 1);
    };

    const hideOverlayAndFocusInput = () => {
        setShowOverlay(false);
        otpRef.current?.reset();
    };

    const verify = async (otp?: string) => {
        if (otp) {
            trackInput('email_verify', 'enter_email_otp');

            try {
                await verifyOtpCode('Email', otp);

                props.onVerify?.();
            } catch (error) {
                otpRef.current?.reset();
                tryShowOverlay();
                setError(ensureResponseError(error));
            }
        }
    };

    const change = async () => {
        trackClick('email_verify', 'change_email');

        props.onChange?.();
    };

    const resend = async () => {
        trackClick('email_verify', 'resend_email_otp');

        setShowHint(true);
        tryShowOverlay();

        try {
            await generateOtpCode('Email', undefined, i18n.language);
        } catch (error) {
            setError(ensureResponseError(error));
        }
    };

    const noAccess = () => {
        trackClick('email_verify', 'no_access');

        props.onNoAccess?.();
    };

    return (
        <Page>
            <MailRegular className={classes.icon} />
            <Title>{t('verifyEmail.emailOtp.title')}</Title>
            <Body>
                <Trans
                    t={t}
                    i18nKey="verifyEmail.emailOtp.subTitle"
                    values={{
                        email: props.email,
                    }}
                />
            </Body>

            <OtpInput
                ref={otpRef}
                error={error && <ErrorTrans t={t} error={error} i18nKey="verifyEmail.emailOtp.error.response" />}
                disabled={loading}
                onInput={verify}
                onResend={resend}
            />

            {showHint && (
                <Alert className={classes.alert} type="info">
                    {t('verifyEmail.emailOtp.hint')}
                </Alert>
            )}

            {props.onNoAccess && (
                <Link className={classes.link} inline onClick={noAccess}>
                    {t('verifyEmail.emailOtp.noAccess')}
                </Link>
            )}

            <Dialog
                open={showOverlay}
                onOpenChange={() => setShowOverlay(false)}
                align="bottom"
                dismissible={false}
                icon={<MailReadRegular />}
                title={t('verifyEmail.emailOtp.overlayTitle')}
                actions={[
                    { text: t('verifyEmail.emailOtp.changeEmail'), onClick: change },
                    {
                        text: t('verifyEmail.emailOtp.enterCode'),
                        style: 'preferred',
                        onClick: hideOverlayAndFocusInput,
                    },
                ]}
            >
                <Body block>
                    <Trans
                        t={t}
                        i18nKey="verifyEmail.emailOtp.overlayContent"
                        components={{
                            email: <span className={classes.email}>{props.email}</span>,
                        }}
                    />
                </Body>
            </Dialog>
        </Page>
    );
};

const useStyles = makeStyles({
    email: {
        fontWeight: tokens.fontWeightSemibold,
    },
    alert: {
        margin: 0,
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
        textAlign: 'center',
    },
    icon: {
        height: tokens.iconSizeTitle,
        width: tokens.iconSizeTitle,
    },
});
