import { createContext, PropsWithChildren, useContext } from 'react';

import { KycRequest } from './models';

export type ActiveKycRequestType = 'alert' | 'page';

export type ActiveKycRequestProviderProps = {
    mode: ActiveKycRequestType;
    onSkip?: (kycRequest: KycRequest) => void;
    onSeen?: (kycRequest: KycRequest) => void;
    current: KycRequest;
};

export type ActiveKycRequestContext = ActiveKycRequestProviderProps;

const Context = createContext<ActiveKycRequestContext | null>(null);

export const ActiveKycRequestProvider: React.FC<PropsWithChildren<ActiveKycRequestProviderProps>> = ({ children, ...props }) => {
    return <Context.Provider value={props}>{children}</Context.Provider>;
};

export const useActiveKycRequest = (): ActiveKycRequestContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useActiveKycRequest must be used within a ActiveKycRequestProvider');
    }

    return context;
};
