import { makeStyles } from '@fluentui/react-components';
import { ErrorCircleRegular, InfoRegular } from '@fluentui/react-icons';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { AlertCard, Body, Button, Link } from '@zastrpay/components';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { useActiveKycRequest } from './ActiveKycRequestProvider';
import { isRequiredKycRequest } from './models';

export type KycRequestAlertProps = {
    title: string;
    action: string;
    onAction?: () => void;
};

export const KycRequestAlert: React.FC<PropsWithChildren<KycRequestAlertProps>> = ({ action, onAction, title, children }) => {
    const classes = useStyles();

    const { t } = useTranslation('kyc-requests');
    const { mode, onSeen, current } = useActiveKycRequest();

    const rejectionReason =
        current?.stateDetails?.dataMismatchReason ??
        current?.stateDetails?.invalidDocumentReason ??
        current?.stateDetails?.poorDocumentQualityReason;

    const due = current && isRequiredKycRequest(current);
    const message = current?.state === 'DataRejected' ? t('rejected.subTitle', { context: rejectionReason }) : children;

    useEffect(() => {
        trackPage('kyc_request_info', { kyc_req_type: current?.type, kyc_req_required: due });

        onSeen?.(current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAction = () => {
        trackClick('kyc_request_info', 'upload_now', { kyc_req_type: current?.type });

        onAction?.();
    };

    switch (mode) {
        case 'alert':
            return (
                <AlertCard type={due ? 'error' : 'warning'} title={title}>
                    <Body align="start" className={classes.alertMessage}>
                        {message}
                    </Body>
                    {due ? (
                        <Button className={classes.action} appearance="primary" size="large" onClick={handleAction}>
                            {action}
                        </Button>
                    ) : (
                        <Link className={classes.link} onClick={handleAction}>
                            {action}
                        </Link>
                    )}
                </AlertCard>
            );
        case 'page':
            return (
                <MessagePage
                    icon={due ? <ErrorCircleRegular className={classes.overdue} /> : <InfoRegular className={classes.pending} />}
                    title={title}
                    action={action}
                    message={message}
                    onAction={handleAction}
                />
            );
    }
};

const useStyles = makeStyles({
    link: {
        fontWeight: tokens.fontWeightSemibold,
        display: 'block',
        marginTop: tokens.spacingVerticalS,
        padding: '0',
    },
    action: {
        marginTop: tokens.spacingVerticalS,
        marginBottom: tokens.spacingVerticalXS,
    },
    overdue: {
        color: tokens.customPaletteRed,
    },
    pending: {
        color: tokens.customPaletteYellowDark,
    },
    alertMessage: {
        color: 'inherit',
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
    },
});
