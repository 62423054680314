import { makeStyles } from '@fluentui/react-components';
import { Trans, useTranslation } from 'react-i18next';

import { useAuth } from '@zastrpay/auth';
import { AlertCard, Body, Link } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export const CustomerBlockedAlert: React.FC = () => {
    const classes = useStyles();
    const { customer } = useAuth();
    const shouldShowBlockedBanner = customer?.state === 'Blocked';

    const { t } = useTranslation('portal');

    return shouldShowBlockedBanner ? (
        <AlertCard type="error" title={t('home.accountBlockedError.title')}>
            <Body align="start" className={classes.alertMessage}>
                <p>{t('home.accountBlockedError.subTitle')}</p>
                <p>
                    <Trans
                        t={t}
                        i18nKey="home.accountBlockedError.contactSupport"
                        components={{
                            email: (
                                <span>
                                    <Link className={classes.email} href={`mailto:${t('home.accountBlockedError.contactEmail')}`}>
                                        {t('home.accountBlockedError.contactEmail')}
                                    </Link>
                                </span>
                            ),
                        }}
                    />
                </p>
            </Body>
        </AlertCard>
    ) : null;
};

const useStyles = makeStyles({
    email: {
        fontWeight: tokens.fontWeightSemibold,
    },
    alertMessage: {
        color: 'inherit',
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
    },
});
