import { makeStyles } from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { dateUtil, register } from '@zastrpay/common';
import { Button, InputField, SelectField, SplitDateInputField } from '@zastrpay/components';
import { useCountries } from '@zastrpay/hooks';
import { Page, PageTitle } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { Identity } from '../models';

export type GeneralData = Pick<Identity, 'countryOfBirth' | 'dateOfBirth' | 'nationality' | 'placeOfBirth'>;

export type GeneralDataFormProps = {
    defaultValues?: Partial<GeneralData>;
    onComplete?: (details: GeneralData) => void;
};

const CITY_REGEX = /^[\p{L}0-9', \-.\\/()]{2,50}$/u;

export const GeneralDataForm: React.FC<GeneralDataFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('registration');

    useEffect(() => {
        trackPage('general_details');
    }, []);

    const { control, handleSubmit } = useForm<GeneralData>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: props.defaultValues && {
            ...props.defaultValues,
            dateOfBirth: props.defaultValues.dateOfBirth,
        },
    });

    const { countries } = useCountries({ sort: 'name' });

    const countryOptions = useMemo(
        () => [
            { key: 'none', text: '', value: '' },
            ...countries.map((country) => ({ key: country.countryCode, text: country.name, value: country.countryCode })),
        ],
        [countries],
    );

    const maxDateOfBirth = useMemo(() => {
        const legalAge = 18;

        const value = dateUtil.subYears(dateUtil.subDays(new Date(), 1), legalAge);
        const formatted = dateUtil.formatIsoDate(value);

        return { value, formatted };
    }, []);

    const validateDateOfBirth = (value: string) => {
        const parsedValue = dateUtil.parseIsoDate(value);

        if (!parsedValue) {
            return t('generalDetails.error.validation', { context: 'General' });
        }

        if (parsedValue > maxDateOfBirth.value) {
            return t('generalDetails.error.validation', { context: 'NotLegalAge' });
        }
    };

    const submit = handleSubmit((data) => {
        trackClick('general_details', 'continue');

        props.onComplete?.(data);
    });

    return (
        <Page align="fill">
            <PageTitle title={t('generalDetails.title')} subTitle={t('generalDetails.subTitle')} />

            <SplitDateInputField
                {...register(control, 'dateOfBirth', { required: true, validate: validateDateOfBirth })}
                label={t('generalDetails.dateOfBirth')}
                autoComplete="bday"
            />

            <InputField
                {...register(control, 'placeOfBirth', { required: true, pattern: CITY_REGEX })}
                size="large"
                label={t('generalDetails.placeOfBirth')}
                placeholder={t('generalDetails.placeOfBirthPlaceholder') ?? undefined}
                hint={{
                    className: classes.hint,
                    children: (
                        <>
                            <InfoRegular className={classes.hintIcon} />
                            {t('generalDetails.placeOfBirthHint')}
                        </>
                    ),
                }}
            />

            <SelectField
                options={countryOptions}
                {...register(control, 'countryOfBirth', { required: true })}
                size="large"
                label={t('generalDetails.countryOfBirth')}
            />

            <SelectField
                options={countryOptions}
                {...register(control, 'nationality', { required: true })}
                size="large"
                label={t('generalDetails.nationality')}
            />

            <Button appearance="primary" onClick={submit} size="large">
                {t('generalDetails.submit')}
            </Button>
        </Page>
    );
};

const useStyles = makeStyles({
    hint: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalXS,
    },
    hintIcon: {
        color: tokens.customPaletteBlue,
    },
});
